footer {
  background-color: #bd8508;
  padding-top: 16px;
  padding-bottom: 25px;
}

#newsletter2 {
  color: black;
  margin-bottom: 12px;
  margin-top: 10px;
  border-color: white;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 5px;
  border-top: white;
  border-right: white;
}

.link-footer-plan > li {
  margin-bottom: 10px;
}

.logo-img {
  width: 92px;
  height: 80px;
}

.nom-cliquable {
  color: white;
}

.input-color {
  color: white;
}

.coeur-adresse-mail {
  width: 21px;
  height: 21px;
  margin-right: 6px;
}

.div-footer-envoyer {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding: 24px;
  border-radius: 10px;
}

.footer-pos {
  display: flex;
  flex-direction: column;
}

.iconBy {
  color: white;
}

.foot-display {
  color: white;
  text-align: center;
  width: 90%;
  margin: auto;
}

.foot-news {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
  margin-bottom: 25px;
}

.foot-news a {
  color: white;
}

.drapeau-p {
  margin-top: 5px;
}

.marg-b {
  margin-bottom: 6px;
}

.evea {
  margin-top: 7px;
}

.team-title {
  color: white;
  font-weight: bold;
  padding-left: 2rem;
}

.link-team2 {
  color: white;
  cursor: pointer;
}

.link-drapeau {
  display: flex;
  justify-content: center;
}

.link-footer-plan > li {
  text-align: center;
  list-style: none;
}

.link-utiles-li {
  display: block;
  cursor: pointer;
}

.stay-tune-para {
  margin-top: 10px;
  width: 100%;
  margin: revert;
}

.footer2 {
  display: flex;
  flex-direction: row;
}

.img-contact {
  width: 31px;
  height: 31px;
  margin-top: 14px;
  margin-left: 10px;
}

.div-logo-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.copyright {
  color: white;
  width: 70%;
  margin: auto;
  text-align: center;
}

.evea {
  color: white;
}

.contact-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nom-footer {
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
}

.version-footer {
  text-align: center;
}

#errorlabelEmail2 {
  font-size: 12px;
  color: black;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 6px;
}

.img-drapeau-footer {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  cursor: text;
}

.nom-cliquable {
  color: white;
}

.text-facebook {
  margin-top: 6px;
  margin-left: 6px;
  cursor: initial;
}

.img-linkedin {
  width: 32px;
  height: 33px;
  margin-left: 15px;
}

.bas-footer {
  background-color: #c18b17;
  padding: 9px 0px 9px 0px;
  color: white;
}

.drapeau-div {
  display: flex;
  width: 50%;
  margin: auto;
}

.btn-footer {
  margin-top: 16px;
  position: relative;
  width: 150px;
}

.send-footer-button {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 7px;
  margin-left: 6px;
}
