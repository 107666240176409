.acticle-container {
    width: 82%;
    margin: auto;
    padding-top: 180px;
}

.article-content {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
}

.img-article {
    width: auto;
    height: 335px;
    border-radius: 5px;
    object-fit: cover;
    object-position: bottom;
}

.img-article2 {
    width: auto;
    height: 335px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
}

.title-article-page {
    font-size: 29px;
    color: #777a81;
    font-weight: bold;
    text-align: center;
    margin: auto;
    margin-bottom: 22px;
    padding-top: 40px;
    width: 80%;
}

.para-actu-article {
    text-align: center;
    padding-top: 44px;
    font-size: 18px;
    font-weight: 400;
    color: #777a81;
    margin-bottom: 20px;
}

.article-seo {
    width: 90%;
    margin: auto;
    margin-top: 22px;
    padding: 10px;
    padding-bottom: 32px;
    text-align: center;
}

.art-marg {
    margin-top: 40px;
}

.ancre-art {
    color: #bd8508;
    font-weight:bold;
}

.ancre-art:hover {
    color: #e3bb5c;
}
.read {
    text-align: center;
    padding-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #777a81;
    }

.li-para-actu {
    font-size: 1rem;
    font-weight: 400;
    color: #777a81;
}
.ul-para-actu {
    width: 80%;
    margin: auto;
}

.para-actu-art2 {
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1rem;
    font-weight: 400;
    color: #777a81;
}