/* Style commun à toutes les pages + mediaQueries*/
* {
  box-sizing: border-box;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #292929;
  background-color: #fff;
}

@font-face {
  font-family: 'Playlist.otf';
  src: url('../fonts/Playlist.otf');
}


h1,
h2 {
  color: #777a81;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
  line-height: 1.4;
}

h3 {
  font-size: 18px;
  color: #292929;
  font-family: 'Roboto', sans-serif;
}

h4 {
  padding: 7px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #777a81;
  font-family: 'Roboto', sans-serif;
}

#button-retour {
  cursor: pointer;
  border-radius: 3px;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 20px;
  opacity: 1;
  z-index: 99999;
  transition: all ease-in 0.2s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  text-decoration: none;
  border-color: transparent;
}

#button-retour:before {
  content: '\25b2';
}
#button-retour:hover {
  background: rgba(189, 133, 8, 8);
}

.label-choice {
  text-align: left;
}

#discover {
  font-size: 13px;
  color: black;
}

.art-buttons-div {
  display: flex;
  flex-direction: column;
}

.btnOne {
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
  border-radius: 5px;
}

/* ==================================================================================================*/
@media all and (min-width: 359px) {
  .btn-footer {
    margin-top: 16px;
    position: relative;
    width: 180px;
  }
}

/* ==================================================================================================*/
@media all and (min-width: 375px) {
  .icon-pop {
    left: 304px;
  }

  #message {
    top: 395px;
    left: 124px;
  }

  .flyImg {
    top: 21px;
    right: 78px;
  }
}

@media all and (min-width: 424px) {
  .icon-pop {
    left: 348px;
  }

  .div-but-formul {
    width: 89%;
    margin-left: auto;
    margin-right: auto;
  }

  .flyImg2 {
    top: 15px;
    left: 84px;
  }
}

/* =========================================================================================*/
@media all and (min-width: 600px) {
  .title-header-first {
    position: absolute;
    left: 76px;
    bottom: 282px;
  }

  .div-para-cahier-ex {
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 111px;
    justify-content: space-between;
  }
  .p-cahier-exemple {
    width: 70%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .div-img-menus-ex {
    display: flex;
    align-items: center;
  }
  .img-cahier-qrcode-menu1 {
    width: 254px;
    height: 270px;
  }
  .img-cahier-qrcode-menu2 {
    width: 305px;
    height: 225px;;
  }

  .div-button-menus-ex1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  .div-button-menus-ex2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    justify-content: space-between;
  }
  .div-video-demo {
    width: 74%;
    margin: auto;
  }

  .video-demo-resto {
    width: 500px;
    margin-bottom: 0px;
  }

  .p-fonct-reverse:after {
    display: flex;
    content: '';
    position: absolute;
    left: 0px;
    top: 60%;
    width: 0;
    height: 0;
    border: 38px solid transparent;
    border-right-color: #bd8508;
    border-left: 0;
    border-bottom: 0;
    margin-top: -19px;
    margin-left: -38px;
  }

  .p-fonct:after {
    display: flex;
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 38px solid transparent;
    border-left-color: #bd8508;
    border-right: 0;
    border-bottom: 0;
    margin-top: -19px;
    margin-right: -38px;
  }

  .div-cartes-fonct {
    width: 65%;
  }

  .p-fonct-reverse {
    font-size: 20px;
    position: relative;
    background: #bd8508;
    color: white;
    border-radius: 0.4em;
    padding: 10px;
    width: 100%;
    margin: auto;
    margin-left: 44px;
  }

  .div-cartes-fonct {
    display: flex;
    flex-direction: row;
  }
  .div-slide-cartes {
    display: flex;
    flex-direction: row;
  }

  .div-slide-cartes-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .p-cahier {
    margin-bottom: 23px;
  }

  .img-cahier-qrcode {
    width: 350px;
    height: 280px;
    margin: auto;
    border-radius: 5px;
    margin-right: 12px;
  }

  .div-para-cahier {
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 111px;
  }

  .img-cahier-index {
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    margin-top: 104px;
  }

  .div-digit-container {
    width: 90%;
    margin: auto;
  }
  .div-digit-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .div-para-digit {
    text-align: center;
    padding: 10px;
    width: 29%;
  }
  .plus2 {
    position: relative;
  }
  .para-digit {
    margin-bottom: 30px;
  }
  .menus {
    margin-bottom: 60px;
  }
  .para-marg {
    margin-bottom: 20px;
  }

  .aSupp {
    margin-left: 32px;
  }

  .description-mobile-marg {
    margin-bottom: 60px;
  }

  .div-footer-envoyer {
    width: 80%;
    margin: 0px;
  }

  .plus2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .description-mobile-marg {
    margin-bottom: 0px;
    width: 50%;
  }

  .navbar-item > .logo-img1 {
    width: 50px;
    height: 50px;
    max-height: inherit;
  }

  .d-flex {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .div-card-description {
    display: flex;
    flex-direction: row;
  }

  .div-button-menu {
    padding-bottom: 40px;
    margin-top: 70px;
  }

  .container-tarifs {
    width: 90%;
    margin: auto;
  }

  .container-description {
    width: 93%;
    margin: auto;
    margin-top: 50px;
  }

  .img-description {
    width: 199px;
    height: 199px;
    margin: auto;
    border-radius: 50%;
  }

  .line {
    width: 100%;
    height: 1px;
    border-style: dashed none none;
    border-width: 1.9px;
    border-color: #db8508;
  }

  .div-line {
    display: flex;
    align-items: center;
    width: 30%;
    padding-bottom: 249px;
  }

  .description-p-title {
    font-weight: bold;
    color: #bd8508;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .title-description {
    color: #ffffff;
    width: 48px;
    border: solid 1px #bd8508;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 44px;
    justify-content: center;
    background-color: #bd8508;
  }

  .div-title-description {
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }

  .div-img-description {
    width: 200px;
    height: 200px;
    border: 1px solid beige;
    border-radius: 50%;
  }

  .container-img {
    display: flex;
    flex-direction: column;
  }

  .card-description-p {
    padding-top: 22px;
    width: 100%;
    margin: auto;
  }

  .button-tarifs {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .div-img-ipad {
    width: 50%;
  }

  .img-ipad {
    width: 100%;
    height: 75%;
  }

  .img-mobile {
    width: 400px;
    height: 502px;
  }

  .container-avis {
    width: 90%;
    margin: auto;
  }

  .but-2 {
    margin-bottom: 10px;
  }

  .aide-header {
    font-size: 62px;
  }

  .link-footer-plan > li {
    text-align: left;
    list-style: none;
  }

  .link-utiles-li {
    display: flex;
    flex-direction: row;
  }

  .card-3 {
    width: 40px;
    height: 40px;
  }
  /*a propos*/
  .team-second-section {
    margin-bottom: 170px;
  }
  .team-values-bis {
    width: 65%;
    margin: auto;
  }
  .team-values-bis {
    width: 65%;
    margin-right: auto;
  }
  .team-first-section {
    margin-bottom: 170px;
  }
  .team-values {
    width: 65%;
    margin-left: auto;
  }

  .div-img-cake {
    width: 20%;
    margin: auto;
  }

  .team-div-container {
    padding-top: 200px;
  }

  .display-mobile {
    display: block;
  }

  .team-first-section {
    display: flex;
    flex-direction: row;
  }

  .team-second-section {
    display: flex;
    flex-direction: row-reverse;
  }

  .equipe-a {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 70px;
  }

  .equipe-b {
    display: flex;
    flex-direction: row;
    margin-bottom: 70px;
  }

  .waves-effect.waves-light {
    background-color: #bd8508;
    color: white;
    font-weight: 164px;
    font-weight: 500;
    display: -webkit-inline-flex;
  }

  .waves-effect.waves-light:hover {
    background-color: #e3bb5c;
    color: white;
  }

  .header-buttons {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .row .col.s6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .card-front-back {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .box {
    margin-bottom: 0px;
    width: 214px;
    height: 320px;
  }

  .swipe-back-p {
    margin-top: 0px;
    padding: 6px;
  }

  .title-cards {
    font-size: 16px;
    margin-top: 18px;
    padding: 6px;
  }

  #menu-simple-restaurateur {
    width: 100%;
    margin: auto;
  }

  .card {
    margin-bottom: 0px; 
  }

  .div-card-img {
    display: flex;
    align-items: center;
    cursor: text;
  }

  .card-img-top {
    height: 120px;
  }

  .card-title-confiance {
    color: #bd8508;
    margin-left: 5px;
  }

  .star-confiance {
    width: 20px;
    height: 20px;
  }

  .marg-star {
    margin-left: 100px;
  }

  .p-confiance {
    margin: 0;
    text-align: center;
    margin-top: 36px;
    color: #292929;
  }

  .row .col.m6 {
    width: 33%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .connect-header {
    margin-left: 56px;
  }

  .img-nom-site {
    display: flex;
    margin-top: 26px;
    margin-left: 2px;
    font-size: 22px;
  }

  .font-tigroo-name {
    color: #bd8508;
    font-size: 20px;
  }

  .title-container-accordion {
    margin-bottom: 60px;
    width: 70%;
  }

  .articles-mot-cle {
    width: 30%;
    margin: initial;
    display: flex;
    flex-direction: column;
  }

  /*menu digitalisé*/
  /*.div-cahier-button {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
  }*/

  .first {
    background-image: url('../images/img-header.svg');
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
    width: 46%;
    margin-top: 22px;
  }

  #div-tigroo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 782px;
    margin-top: 46px;
    justify-content: center;
  }

  .div-img-ipad {
    display: flex;
    width: 35%;
  }

  .exemple-cahier-div2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 85px;
  }

  .img-exemple-cahier-rappel {
    width: 350px;
    height: 350px;
    border-radius: 60%;
    margin-top: 28px;
  }

  .circle-number {
    margin: 10px;
    width: 50px;
    background: #bd8508;
    color: white;
    height: 50px;
    text-align: center;
    border-radius: 100px;
    line-height: 50px; /* 100 - 10 */
    font-size: 22px;
    margin-right: 40px;
  }

  .circle-div {
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: auto;
    justify-content: space-evenly;

    padding-top: 20px;
  }

  .circle-part {
    padding-bottom: 65px;
  }

  .cahier-number {
    width: 80%;
    margin: auto;
  }

  .cahier-rappel-img {
    display: flex;
    flex-direction: row;
    width: 72%;
    margin: auto;
    margin-bottom: 22px;
    justify-content: center;
  }

  /*fin menu*/

  .barre-navigation {
    width: 99%;
    height: auto;
    height: 94px;
  }

  #passwordContainer {
    margin: auto;
    margin-top: 25px;
    width: 218px;
  }

  .div-plus {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 40%;
    margin: auto;
  }

  .img-cahier {
    width: 245px;
    height: 440px;
  }

  .passwordLabelInterrogation {
    display: flex;
  }

  .passwordLabelInterrogation {
    display: flex;
    margin: auto;
  }

  .container-cgu {
    padding: 20px;
  }

  .div-cahier-img {
    display: flex;
    flex-direction: row;
  }

  .exemple-cahier {
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }

  .work-part2 {
    text-align: center;
    width: 100%;
  }

  .pad {
    font-size: 40px;
  }

  .details-form {
    margin-top: 50px;
  }

  .chevron-accordeon {
    top: 17px;
    left: 30px;
    width: 35px;
    height: 35px;
  }

  .exemple {
    padding: 14px;
    margin-bottom: 10px;
    width: 31%;
  }

  .div-number1 {
    width: 56%;
  }

  .nav-bar {
    width: 95%;
  }

  .flyImg2 {
    top: 18px;
    left: 48px;
  }

  .fixe {
    height: 78px;
    background-color: white;
    transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 20px 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }

  .color-navbar {
    background-color: white;
  }

  .form-input {
    width: 200px;
  }

  .working-art {
    padding-bottom: 50px;
  }

  /*partie page 404*/
  .lost-container {
    width: 70%;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 50px;
    padding: 40px;
  }

  .lost-div {
    text-align: center;
  }

  .lost-title {
    font-size: 50px;
    padding: 10px;
    margin-bottom: 20px;
    color: rgba(113, 71, 255, 0.7);
  }

  .lost-div p {
    font-size: 20px;
    padding: 6px;
  }

  .lost-p {
    margin-bottom: 28px;
  }

  /*img {
    width: 74px;
    height: 74px;
  }*/

  h2 {
    font-size: 36px;
    line-height: 1.4;
  }

  .margD {
    margin-bottom: 60px;
    margin-top: 80px;
  }

  .pop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 6%;
    color: white;
  }

  .eclair {
    background-color: rgba(113, 71, 255, 0.9);
    background-color: rgb(196, 140, 4);
    margin: auto;
    position: relative;
    overflow: scroll;
    padding: 35px;
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  .pop-title {
    font-size: 40px;
    text-align: center;
  }

  p.div-pop {
    margin-top: 16px;
  }

  .div-pop {
    padding: 10px;
  }

  .but-pop {
    width: 39%;
    background-color: rgb(128, 154, 118);
    color: white;
    border: none;
    padding: 12px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    margin: auto;
    margin-top: 17px;
    position: relative;
  }

  .flyImg {
    width: 11%;
    height: 63%;
    top: 23px;
    right: 170px;
  }

  .aPop {
    text-decoration: none;
  }

  .input-pop {
    padding: 12px;
    border: none;
    border-radius: 5px;
    width: 230px;
  }

  .essai {
    position: absolute;
    margin-top: 60px;
  }

  .icon-pop {
    position: relative;
    top: 34px;
    left: 584px;
    font-size: 20px;
    background-color: rgba(196, 140, 4, 0.1);
    color: white;
    text-decoration: none;
  }

  .chevron {
    text-align: center;
    margin-top: 5px;
    display: block;
    z-index: 1;
  }

  .overflow-hidden {
    display: none;
  }

  .display {
    left: 0;
  }

  .size-ipad {
    font-size: 30px;
  }

  .desktop {
    display: flex;
    width: 74%;
    justify-content: space-around;
  }

  .desktop a {
    text-decoration: none;
    color: white;
    padding: 9px;
    font-size: 11px;
    font-weight: bold;
  }

  .desktop a:hover {
    opacity: 0.8;
  }

  .img-tigroo-qr {
    width: 40%;
    margin-left: 53px;
  }

  .menu-dig {
    width: 70%;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .use-menu {
    margin-bottom: 70px;
  }

  .div-number {
    box-shadow: 7px 6px 7px 0px rgba(196, 140, 4, 0.8);
    border-radius: 57%;
    width: 55%;
    margin: auto;
    margin-bottom: 22px;
  }

  .back-cgu {
    position: absolute;
    width: 35px;
    height: 35px;
    margin-top: 30px;
    margin-left: 52px;
  }

  .title-cgu {
    text-align: center;
    padding: 30px;
    font-size: 22px;
    width: 70%;
    margin: auto;
    padding-bottom: 45px;
    line-height: 1.4;
    margin-top: 64px;
    font-weight: 700;
  }

  .subTitle-cgu {
    margin-bottom: 0px;
  }

  .container-cgu p {
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .container-cgu ul {
    width: 70%;
    margin: auto;
  }

  .form-dispo {
    padding-bottom: 48px;
  }

  .pContainer {
    width: 60%;
    margin: auto;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  @keyframes myfirst {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .row-features {
    display: contents;
  }

  .art-ipad {
    display: flex;
    width: 90%;
    justify-content: space-around;
    margin: auto;
  }

  .art-ipad-2 {
    flex-direction: row-reverse;
  }

  .how-p-ipad {
    line-height: 1.3;
  }

  .fa-circle {
    font-size: 75px;
    color: white;
  }

  .arrow {
    display: none;
    width: 100px;
    height: 100px;
    position: relative;
    top: 50px;
  }

  .arrowBis {
    display: none;
    width: 100px;
    height: 100px;
    position: relative;
    top: 50px;
    left: 90px;
  }

  .one {
    position: absolute;
  }

  .two {
    position: relative;
  }

  .div-ex-ipad {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .div-ex-texte {
    padding: 20px;
    line-height: 1.4;
    margin: auto;
  }

  /*menu-ex*/
  .menuex-p {
    text-align: center;
    padding: 20px;
  }

  .input-footer-envoyer {
    width: 158px;
    margin-right: 50px;
  }

  input[type='email']:not(.browser-default),
  input[type='password']:not(.browser-default) {
    width: 100%;
  }

  .title-contact-div {
    color: white;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .div-adresse {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 0;
  }

  .foot-display p,
  .foot-display a,
  .foot-display h3 {
    text-align: left;
  }

  .but-pop2 {
    width: 230px;
    color: rgb(128, 154, 118);
    border: 1px solid rgb(128, 154, 118);
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    margin: auto;
    margin-top: 10px;
    background-color: white;
    position: relative;
  }

  .div-send {
    text-align: center;
  }

  #message {
    top: 430px;
    left: 352px;
    width: 35%;
  }

  #top-actu {
    height: 408px;
  }

  #top-equipe {
    height: 408px;
  }

  /*accordeon*/
  .collapsible.popout {
    width: 70%;
    margin: auto;
  }

  .collapsible-body {
    text-align: center;
    width: 78%;
    margin: auto;
  }

  /*carousel*/
  .div-carousel {
    width: 90%;
    margin: auto;
    margin-top: 30px;
  }

  .title-carousel {
    text-align: center;
    margin-bottom: 40px;
  }

  .img-coeur {
    width: 50px;
    height: 50px;
  }

  .img-coeur-confiance {
    text-align: center;
  }

  .stay-tune-para {
    margin-top: 10px;
    width: 80%;
    margin: revert;
  }

  .div-interrogation {
    margin: auto;
    margin-top: 28px;
    width: 282px;
    margin-bottom: 15px;
  }

  .div-interrogation2 {
    margin: auto;
    width: 282px;
    margin-bottom: 15px;
  }

  .container-cards-features {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    justify-content: space-evenly;
  }

  .features-p {
    height: 250px;
    padding: 10px px 0px 22px;
    padding: 22px;
  }

  .nom-footer {
    font-size: 16px;
  }

  .features-marg {
    margin-bottom: 80px;
    margin-top: 30px;
  }

  .contact-footer {
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }

  .copyright {
    color: white;
    margin-right: 45px;
    width: 510px;
    margin: 0;
  }

  .img-drapeau-footer {
    display: block;
    cursor: text;
  }

  .card.horizontal {
    display: flex;
    width: 45%;
    margin-bottom: 65px;
    padding-bottom: 24px;
  }

  .drapeau-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
  }

  .div-logo-contact {
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }

  .box-back {
    padding: 10px;
  }

  .white-text {
    margin-top: 0px;
  }

  .table-mobile {
    width: 80%;
    margin: auto;
  }

  table td {
    border: 1px solid lightgrey;
    padding: 12px;
    color: #777a81;
    width: 33%;
  }

  .img-check {
    width: 25px;
    height: 25px;
    text-align: center;
  }

  .td-img-check {
    text-align: center;
  }

  thead tr th {
    padding: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  .icone-confiance {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  .link-drapeau {
    display: flex;
    flex-direction: column;
  }

  .foot-div-logo {
    display: flex;
    flex-direction: column;
    width: 7%;
    margin-right: 30px;
    margin-left: 10px;
  }

  .foot-news {
    display: flex;
    flex-direction: column;
    width: 23%;
    margin: 0;
  }

  .foot-news-adresse {
    display: flex;
    flex-direction: column;
    width: 13%;
    margin: 0;
  }

  .contact-div {
    display: flex;
    flex-direction: column;
    width: 28%;
    margin: 0;
  }
  .foot-display {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 20px;
    width: 100%;
  }
}

/*==========================================================================================================*/
@media all and (min-width: 767px) {
  .play-btn {
    width: 70px;
    height: 70px;
    background: radial-gradient(#bd8508 60%, rgba(255, 255, 255, 1) 62%);
    top: 370px;
    left: 208px;
  }

  #mapid {
    height: 400px;
    width: 400px;
    margin: auto;
    border-radius: 5px;
  }

  .container-map {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
  }

  .video-container {
    padding-top: 111px;
  }

  .div-video-responsive {
    text-align: center;
  }

  .video {
    width: 470px;
  }

  .demo-title {
    margin-bottom: 70px;
  }

  .btn-width {
    width: 48%;
    margin: auto;
  }

  .btnMargBottom {
    width: 48%;
    margin: auto;
    margin-bottom: 20px;
  }

  .p-fonct {
    width: 100%;
    margin: auto;
    padding: 12px;
    margin-right: 48px;
  }

  .btn-footer {
    margin-top: 16px;
    position: relative;
    width: 138px;
  }

  nav ul a {
    padding: 0 4px;
  }

  .card-content {
    width: 80%;
    margin: auto;
  }

  .container-confiance {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .div-art-conf {
    margin-bottom: 75px;
    height: 150px;
    width: 100%;
  }

  .maj-conf {
    font-size: 11px;
    font-style: italic;
  }

  .div-art-conf {
    margin-bottom: 75px;
    height: 150px;
  }

  .private-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 90px;
  }

  .politique-div-container {
    padding-top: 110px;
    width: 60%;
    margin: auto;
    margin-bottom: 90px;
    text-align: initial;
  }

  .div-button-art {
    text-align: center;
    margin-bottom: 70px;
  }

  .poste {
    width: 27%;
  }

  .div-img-adeline {
    width: 95%;
    margin: auto;
  }

  .second {
    width: 100%;
  }

  .div-facebook {
    text-align: left;
    margin-top: 10px;
  }

  .read-actu {
    text-align: center;
  }

  .share-link-div {
    display: flex;
    flex-direction: row;
  }

  .cards-list {
    width: 85%;
    margin: auto;
    margin-top: 7px;
  }

  .img-card-how {
    width: 200px;
    height: 128px;
  }

  .burger-mobile {
    width: 33px;
    height: 33px;
    margin-top: 20px;
    margin-right: 50px;
  }

  .articles {
    text-align: center;
    width: 100%;
    margin: initial;
  }

  .form {
    width: 48%;
    margin: auto;
  }

  .features-p {
    height: 254px;
  }

  .nom-footer {
    font-size: 15px;
  }

  .desktop a {
    font-size: 14px;
  }

  #message {
    top: 486px;
    left: 416px;
    width: 30%;
    padding: 11px;
  }

  .messageX {
    position: absolute;
    top: -10px;
    right: -6px;
    padding: 10px;
    color: black;
  }

  .flyImg2 {
    top: 17px;
    width: 12%;
    height: 56%;
    left: 65px;
  }
}

@media all and (min-width: 840px) {
  .check {
    width: 268px;
  }

  .desktop a {
    padding: 18px;
  }
}

@media all and (min-width: 920px) {
  .desktop a {
    text-align: center;
    width: 220px;
  }
}

/* =========================================================================================*/
@media all and (min-width: 993px) {
  #mapid {
    height: 500px;
    width: 800px;
    margin: auto;
    border-radius: 5px;
  }

  .video {
    width: 717px;
  }

  .d-flex {
    flex-direction: row;
    margin-bottom: 0px;
  }

  .title-contact-div {
    color: white;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .div-logo-contact {
    display: flex;
    flex-direction: row;
    justify-content: initial;
  }

  .link-drapeau {
    display: flex;
    flex-direction: row;
  }

  #wrapper {
    width: 100%;
    max-width: 964px;
    position: relative;
  }

  #carousel {
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  #carousel::-webkit-scrollbar {
    height: 0;
  }

  #prev,
  #next {
    display: flex;
    justify-content: center;
    align-content: center;
    background: white;
    border: none;
    padding: 8px;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    position: absolute;
  }
  .link-drapeau {
    display: flex;
    justify-content: left;
  }

  #wrapper {
    width: 100%;
    max-width: 964px;
    position: relative;
  }

  #carousel {
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  #carousel::-webkit-scrollbar {
    height: 0;
  }

  #prev,
  #next {
    display: flex;
    justify-content: center;
    align-content: center;
    background: white;
    border: none;
    padding: 8px;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    position: absolute;
  }

  #prev {
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    display: none;
  }

  #next {
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  #content {
    display: grid;
    grid-gap: 16px;
    grid-auto-flow: column;
    margin: auto;
    box-sizing: border-box;
  }

  .item {
    width: 180px;
    height: 180px;
    background: green;
  }

  .waves-effect.waves-light {
    width: 144px;
  }

  .connect-header {
    margin-left: 0px;
  }
}

@media all and (min-width: 1023px) {
  .img-cahier-qrcode-menu2 {
    width: 344px;
    height: 270px;
  }

  .title-header-first {
    position: absolute;
    bottom: 372px;
    left: 82px;
  }
  .first-section-header {
    margin-left: 40px;
  }
  .picture-header {
    width: 570px;
    height: 354Px;
  }
  .marg-button-head-mobile {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .buttons-head-mobile {
    display: initial;
    flex-direction: row;
  }
  .container {
    width: 900%;
    margin: auto;
    margin-top: 98px;
    margin-bottom: 80px;
  }
  .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 45px solid white;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .play-btn {
    width: 150px;
    height: 150px;
    background: radial-gradient(#bd8508 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: absolute;
    top: 344px;
    left: 284px;
    display: block;
    box-shadow: 0px 0px 25px 3px #bd8508;
  }

  .para-art-qrcode {
    width: 40%;
    margin: auto;
    display: flex;
    margin-bottom: 50px;
    justify-content: center;
  }

  .div-video-demo {
    width: 100%;
  }

  .exemple-cahier-div2 {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 85px;
  }

  .div-cartes-fonct {
    width: 54%;
  }

  .div-container-fonct {
    margin-bottom: 60px;
  }

  .p-cahier {
    margin-bottom: 13px;
    padding-top: 26px;
  }

  .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .title-gratuit {
    color: white;
    font-size: 44px;
    font-family: 'Playlist.otf';
  }

  .title-carte {
    font-size: 24px;
    margin-left: 26px;
  }

  .menus {
    margin-bottom: 72px;
  }

  .btn-footer {
    position: relative;
    width: 86%;
    margin: auto;
    margin-top: 20px;
  }

  .btnIpad {
    font-size: 11px;
  }

  .btnMobile {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .description-mobile-margBis {
    margin-bottom: 0px;
    width: 50%;
  }

  .marg-li {
    margin-bottom: 30px;
  }

  .desktop-width {
    width: 50%;
  }

  .navbar-item,
  .navbar-link {
    padding: 0px;
  }

  .container-confiance {
    width: 80%;
    margin: auto;
    display: flex;
  }

  .foot-news-adresse {
    display: flex;
    flex-direction: column;
    width: 19%;
    margin: 0;
  }

  .foot-news {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 0;
  }

  .head-title {
    font-size: 46px;
  }

  .first {
    background-image: url('../images/img-header.svg');
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 5px;
    margin-top: 100px;
  }

  #div-tigroo {
    display: flex;
    flex-direction: row;
    height: 577px;
    margin-top: 0px;
  }

  .first-img-header {
    height: 297px;
  }

  .second {
    width: 61%;
    margin-top: 16px;
  }

  .read-actu {
    text-align: left;
  }

  .news-content {
    display: flex;
    height: 400px;
  }

  .img-actu {
    width: 460px;
    height: auto;
    border-radius: 2px;
    margin-right: 30px;
  }

  .content-actu {
    padding: 10px;
    text-align: left;
    width: 100%;
    margin: none;
    margin-bottom: 10px;
  }

  .para-actu {
    text-align: left;
  }

  .para-actu-qrcode {
    text-align: left;
    margin-top: 28px;
    font-size: 1rem;
    font-weight: 400;
    color: #777a81;
  }

  .title-article-page {
    font-size: 45px;
    text-align: center;
    margin: auto;
    padding-top: 70px;
    width: 80%;
  }

  .marg-top-ipad {
    margin-top: 0px;
  }

  .img-article {
    height: 470px;
  }

  .img-article2 {
    height: 470px;
  }

  .article-seo {
    width: 90%;
    margin: auto;
    padding-bottom: 32px;
    margin-bottom: 22px;
    text-align: left;
  }

  .article-resto-qrcode {
    width: 90%;
    margin: auto;
    margin-bottom: 14px;
    text-align: left;
  }

  .title-actualites {
    text-align: left;
  }

  .connect-header {
    margin-left: 8px;
  }

  nav ul a {
    padding: 0 4px;
  }

  .div-our-team {
    width: 70%;
    margin: auto;
  }

  #menu-simple-restaurateur,
  #menu-simple-client {
    width: 90%;
    margin: auto;
  }

  #menu-simple-client {
    margin-top: 98px;
  }

  .waves-effect.waves-light {
    width: 195px;
  }

  .form {
    width: 50%;
    margin: auto;
  }

  .features-p {
    height: 190px;
  }

  .card.horizontal {
    width: 40%;
    border-left: 2px solid #bd8508;
  }

  .title-feature {
    padding-left: 10px;
    margin-top: 20px;
    text-align: left;
  }

  #passwordContainer {
    width: 300px;
  }

  .barre-navigation {
    width: 95%;
  }

  .desktop {
    width: 80%;
  }

  .espace-button {
    margin-top: 10px;
  }

  .passwordLabelInterrogation {
    width: 340px;
  }

  .div-interrogation {
    width: 300px;
    margin: auto;
    margin-top: 30px;
  }

  .div-interrogation2 {
    width: 300px;
    margin: auto;
    margin-top: 10px;
  }

  .but-pop {
    width: 25%;
  }

  .icon-pop {
    left: 788px;
    font-size: 22px;
  }

  .flyImg {
    top: 20px;
    right: 261px;
    width: 8%;
    height: 75%;
  }

  .flyImg2 {
    top: 16px;
    left: 85px;
    width: 14%;
    height: 65%;
  }

  .back-cgu {
    margin-top: 30px;
    margin-left: 30px;
  }

  .container-cgu ul {
    width: 70%;
    margin: auto;
  }

  .div-number {
    padding: 11px;
    border-radius: 57%;
    width: 41%;
  }

  .first-title {
    font-size: 24px;
    background-color: #7147ff;
    border-radius: 30px;
    color: white;
    width: 10%;
    margin-bottom: 5px;
    text-align: center;
    margin: auto;
    padding: 10px;
  }

  @keyframes slidein {
    from {
      transform: translateX(100vw);
    }

    to {
      transform: translateX(0);
    }
  }

  .but-1 {
    width: 270px;
    color: white;
    border: none;
    margin-bottom: 11px;
    padding: 11px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
  }

  .but-1:hover {
    color: white;
  }

  .but-2 {
    width: 270px;
    padding: 11px;
    font-size: 18px;
    cursor: pointer;
  }

  .size-ipad {
    font-size: 35px;
  }

  .formul-laptop {
    display: flex;
    flex-direction: column;
  }

  .laptop-input {
    width: 340px;
    margin-bottom: 7px;
  }

  .fa-mobile-alt {
    font-size: 175px;
  }

  .p-laptop {
    width: 73%;
    margin: auto;
    line-height: 1.5;
    margin-top: 20px;
  }

  #message {
    top: 359px;
    left: 500px;
    width: 19%;
    padding: 8px;
  }

  #button-retour.button-haut {
    bottom: 95px;
    right: 24px;
    opacity: 1;
  }
}

@media all and (min-width: 1080px) {
  .form-input {
    width: 340px;
  }

  .div-number1 {
    width: 42%;
  }
}

/* =========================================================================================*/
@media all and (min-width: 1200px) {
  #button-retour.button-haut {
    bottom: 85px;
    right: 25px;
    opacity: 1;
    display: flex;
  }
  .btn-menus-ex {
    margin-bottom: 0px;
  }
  .div-button-menus-ex1, .div-button-menus-ex2{
    display: flex;
    flex-direction: row;
  }
  .div-img-menus-ex {
    width: auto;
    margin: auto;
  }

  .title-header-first {
    position: absolute;
    bottom: 432px;
    left: 158px;
  }

  .header-buttons {
    padding-bottom: 20px;
  }

  .card-img-top {
    height: 150px;
  }

  .play-btn {
    top: 412px;
    left: 567px;
  }
  .exemple-cahier-div2 {
    width: 85%;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 85px;
  }

  .video {
    width: 900px;
  }

  .div-cartes-fonct {
    width: 46%;
  }

  .cahier-number {
    width: 80%;
    margin: auto;
    margin-top: 40px;
  }

  .div-line {
    display: flex;
    align-items: center;
    width: 20%;
    padding-bottom: 234px;
  }

  .div-para-cahier {
    padding: 14px;
    box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
  }

  .div-para-cahier-ex {
    padding: 14px;
    box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
  }

  .img-cahier-index {
    margin-right: 40px;
  }

  .para-cahier {
    margin-bottom: 36px;
  }

  .p-cahier {
    width: 82%;
    margin: auto;
  }

  .first-img-header {
    height: 364px;
    cursor: pointer;
  }

  .div-carte-header {
    margin-top: 5px;
  }

  .title-carte {
    margin-left: 0px;
  }

  .marg-top-ipad {
    margin-top: revert;
  }

  .btnIpad {
    font-size: initial;
  }

  .button-like {
    color: white;
    font-size: 12px;
  }

  .navbar-item,
  .navbar-link {
    color: #4a4a4a;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    position: relative;
  }

  .navbar-item > .logo-img2 {
    width: 116px;
    height: 40px;
    max-height: inherit;
  }

  .navbar-item > .logo-img1 {
    width: 54px;
    height: 54px;
    max-height: inherit;
  }

  .img-ipad {
    width: 100%;
    height: 100%;
  }

  .img-mobile {
    width: 644px;
    height: 432px;
  }

  .div-img-adeline {
    width: 89%;
    margin: auto;
  }

  .art-team-adeline {
    width: 90%;
  }

  .poste {
    width: 27%;
  }

  .first {
    background-image: url('../images/img-header.svg');
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 86px;
    margin-top: 69px;;
  }

  #div-tigroo {
    display: flex;
    flex-direction: row;
    height: 580px;
  }

  .second {
    width: 48%;
    margin-top: 0px;
  }

  .box:hover .box-inner {
    transform: rotateY(180deg);
  }

  .title-cards {
    font-size: 18px;
    width: 55%;
  }

  .cards-list {
    width: 75%;
    margin: auto;
    margin-top: 16px;
  }

  nav ul a {
    padding: 0 10px;
  }

  .card.horizontal {
    width: 33%;
  }

  .card-2 {
    width: 40px;
    height: 40px;
  }
  .img-card-how {
    width: 300px;
    height: 190px;
  }
  .swipe-back-p {
    margin-top: 30px;
    width: 80%;
    margin: auto;
    margin-top: 22px;
  }
  .box {
    margin-bottom: 0px;
    width: 300px;
    height: 400px;
  }

  .card {
    width: 96%;
  }

  .waves-effect.waves-light {
    width: 230px;
  }

  .connect-header {
    margin-left: 30px;
  }

  .barre-navigation {
    width: 95%;
  }

  .eclair {
    width: 60%;
  }

  .icon-pop {
    left: 879px;
    top: 39px;
    font-size: 25px;
  }

  .flyImg2 {
    top: 15px;
    left: 131px;
    width: 9%;
    z-index: 20;
  }

  .div-number {
    padding: 11px;
    width: 34%;
  }

  #message {
    top: 358px;
    left: 585px;
    width: 19%;
    padding: 19px 20px 8px 8px;
  }

  .messageX {
    position: absolute;
    top: -8px;
    right: -5px;
    padding: 10px;
    color: black;
  }
}

@media all and (min-width: 1280px) {
  .logo-img1 {
    margin-left: 10px;
  }

  .connect-header {
    margin-left: 87px;
  }

  #message {
    top: 358px;
    left: 612px;
    width: 18%;
    padding: 10px 27px 10px 8px;
  }

  .messageX {
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px;
    color: black;
  }
}

@media all and (min-width: 1368px) {
  .div-carte-header {
    margin-top: 42px;
  }

  .div-label-footer {
    width: 65%;
  }

  .card {
    width: 80%;
  }

  .card.horizontal {
    width: 36%;
  }
}

@media all and (min-width: 1440px) {
  #div-tigroo {
    height: 600px;
  }
  .title-header-first {
    position: absolute;
    bottom: 466px;
    left: 198px;
  }
  .exemple-cahier-div2 {
    width: 80%;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 85px;
  }

  .first-img-header {
    height: 396px;
  }

  .div-carte-header {
    margin-top: 8px;
  }

  .table-mobile {
    width: 100%;
    margin: auto;
  }

  .connect-header {
    margin-left: 181px;
  }

  nav ul a {
    padding: 0 12px;
  }

  .img-nom-site {
    margin-right: 154px;
  }
  .card {
    width: 90%;
  }

  .articles-mot-cle {
    width: 25%;
  }

  .form {
    width: 100%;
    margin: auto;
  }

  .container-tarifs {
    width: 50%;
    margin: auto;
  }

  .eclair {
    width: 50%;
  }

  .flyImg {
    right: 230px;
  }

  .icon-pop {
    left: 900px;
    top: 44px;
  }

  .div-number {
    padding: 12px;
    width: 35%;
  }

  .back-cgu {
    margin-top: 33px;
    margin-left: 101px;
  }

  #message {
    top: 360px;
    left: 700px;
    width: 17%;
    padding: 14px;
  }

  .messageX {
    position: absolute;
    left: 218px;
    top: -7px;
    padding: 9px;
    color: black;
  }

  .flyImg2 {
    top: 16px;
    width: 10%;
    left: 150px;
  }

  .form-width-desktop {
    width: 40%;
    margin: auto;
  }
}

@media all and (min-width: 1600px) {
  .flyImg2 {
    top: 15px;
    width: 10%;
    left: 173px;
  }
}

@media all and (min-width: 1740px) {
  .flyImg2 {
    top: 15px;
    width: 10%;
    left: 196px;
  }
}

@media all and (min-width: 1919px) {
  .title-header-first {
    position: absolute;
    bottom: 492px;
    left: 198px;
  } 
  .head-title {
    margin-bottom: 30px;
  }

  .first-section-header {
    width: 25%;
    margin-left: 183px;
  }

  .play-btn {
    top: 526px;
    left: 573px;
  }

  .div-carte-header {
    margin-top: 19px;
  }

  #div-tigroo {
    height: 646px;
    justify-content: space-evenly;
  }

  .exemple-cahier-div2 {
    width: 60%;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 85px;
  }

  .video-container {
    padding-top: 130px;
    margin-bottom: 0px;
  }

  .video {
    width: 1300px;
  }

  .p-fonct,
  .p-fonct-reverse {
    width: 50%;
  }

  .p-fonct:after {
    right: 0;
    top: 51%;
    border: 38px solid transparent;
    border-left-color: #bd8508;
    border-right: 0;
    border-bottom: 0;
    margin-right: -36px;
  }

  .p-fonct-reverse:after {
    margin-left: -36px;
  }

  .div-footer-envoyer {
    width: 56%;
    margin: 0px;
  }

  .stay-tune-para {
    margin-top: 10px;
    width: 60%;
    margin: revert;
  }
  .btn-width {
    width: 25%;
    margin: auto;
  }

  .btnMargBottom {
    width: 25%;
    margin: auto;
    margin-bottom: 20px;
  }

  .img-cahier-index {
    margin-right: 100px;
  }

  .p-cahier {
    width: 65%;
    margin: auto;
  }

  .img-cahier-qrcode {
    width: 400px;
    height: 330px;
    border-radius: 5px;
    margin-right: 12px;
  }

  .div-label-footer {
    width: 45%;
  }

  .btn-footer {
    width: 50%;
  }

  .first {
    background-image: url('../images/img-header.svg');
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
    width: 36%;
    margin: auto;
    margin-left: 216px;
    margin-top: 65px;
  }

  .first-img-header {
    height: 411px;
  }

  .second {
    width: 54%;
  }
  .div-img-adeline {
    width: 84%;
    margin: auto;
  }

  .connect-header {
    margin-left: 462px;
  }

  nav ul a {
    padding: 0 14px;
  }

  .card.horizontal {
    width: 28%;
  }

  .img-nom-site {
    margin-right: 430px;
  }

  .card {
    width: 70%;
  }

  .connect-header {
    margin-left: 356px;
  }

  .form {
    width: 50%;
    margin: auto;
  }

  .div-pop-position {
    position: relative;
    text-align: left;
  }
}

@media all and (min-width: 2500px) {
  .connect-header {
    margin-left: 700px;
  }
}
