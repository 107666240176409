.actualites-container {
  width: 82%;
  margin: auto;
}

.title-actualites {
  padding-top: 98px;
  padding-bottom: 28px;
  text-align: center;
}

.big-title-actu {
  font-size: 45px;
  color: #777a81;
}

.news-title {
  font-size: 20px;
  color: #777a81;
}

.news-content {
  display: block;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  margin-bottom: 100px;
}

.div-img-actu {
  display: contents;
}

.content-actu {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  width: 90%;
  margin: auto;
}

.para-actu {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 1rem;
  font-weight: 400;
  color: #777a81;
}

.para-color {
  color: #777a81;
  font-size: 21px;
}

.button-actu {
  font-size: 21px;
  transition: all ease 0.5s;
  background: linear-gradient(to left, #fda085, #fdbb00);
  color: white;
  border: none;
  padding: 11px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.div-share-art {
  margin-top: 22px;
}

.lire-ancre {
  color: white;
}

.lire-ancre:hover {
  color: whitesmoke;
}

.div-button-share {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.img-actu {
  border-radius: 2px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.title-article {
  font-size: 29px;
  color: #777a81;
  font-weight: bold;
}

.read-star {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.div-date-art {
  display: flex;
  flex-direction: row;
}

.read-actu {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #777a81;
}

.share-link-div {
  display: flex;
  flex-direction: column;
}

.btnCopy {
  border: none; 
  background-color: #f5F5F5;
  cursor: pointer;
  font-size: 13px;
  width: 20%;
}

.btnCopy:focus {
  outline: none;
  background-color: #f5f5f5; 
}

.img-share-link {
  width: 38px;
  height: 32px;
  margin-top: 4px;
}

.img-share-link2 {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}

.but-art {
  width: 236px;
  color: white;
  border: none;
  margin-bottom: 11px;
  padding: 11px;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #bd8508;
}

.but-art:hover {
  color: white;
  background-color: #e3bc5e;
}

.div-button-art {
  text-align: center;
  margin-bottom: 70px;
}

.art-buttons-div-mobile{
  text-align: center;
  margin-bottom: 60px;
}

.div-dropdown {
  width: 114px;
}

.dropdown-content li:hover {
  background-color: white;
}

.img-facebook {
  width: 32px;
  height: 33px;
}

.div-facebook {
    text-align: center;
    margin-top: 10px;
}

.fb-share-button {
  margin-top: 10px;
}