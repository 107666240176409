#div-actualites {
    padding-top: 335px;
    margin-top: 70px;
}

#top-actu {
    background-image: url('../images/img-princ.svg');
    background-position: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

