/* A propos */
 .display-mobile{
   display: none;
 }

.team-div-container {
  padding-top: 95px;
  width: 80%;
  margin: auto;
  margin-bottom: 92px;
}

.team-first-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.team-second-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.div-our-team {
  width: 80%;
  margin: auto;
}

.team-values {
  width: 88%;
  margin: auto;
}

.team-values-bis {
  width: 88%;
  margin: auto;
  text-align: center;
}

.team-title-prop {
  
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  color: #777A81; 
}

.ap {
  position: absolute;
  top: 141px;
  left: 537px;
  font-size: 33px;
  color: white;
  font-family: auto;
  text-transform: uppercase;
  font-weight: bold;
}

.img-apropos {
  width: 130px;
  height: 130px;
}

.div-img-cake {
  display: flex;
  flex-direction: column;
  width: 43%;
  margin: auto;
  margin-top: 44px;
}

.title-team {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 11px;
}

.team-p {
  font-size: 14px;
}

.marg-left {
  margin-left: auto;
}

.img-team {
  width: 129px;
  height: 129px;
}

.equipe-a {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 86px;
}

.equipe-b {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.art-team-propos {
  margin-bottom: 40px;
}

.art-team-adeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-img-adeline {
  width: 45%;
  margin: auto;
}

.equipe-adeline {
  margin-bottom: 70px;
}



