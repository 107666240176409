/*CGU*/
.fermeture-cgu {
  display: flex;
  width: 5%;
  margin-left: auto;
  text-decoration: none;
  color: black;
  font-size: 14px;
  padding-top: 6px;
  padding-right: 19px;
}

.art-cgu p {
  text-align: left;
}

.body-cgu {
  font-size: 16px;
 
  color: #292929;
  background-color: #f5f5f5;
  background: linear-gradient(to bottom,#bd8508 0%, rgb(227, 187, 92, 0.8) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=0 );
}

.container-cgu {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.title-cgu {
  text-align: center;
  padding: 30px;
  font-size: 16px;
  color: #646464;
  margin-top: 60px;
}

.cgu-li {
  margin-left: 30px;
}

.art-cgu {
  padding: 10px;
}

.subTitle-cgu {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 14px;
}

.container-cgu p {
  line-height: 1.3;
  margin-bottom: 12px;
  font-size: 12px;
}

.container-cgu li {
  font-size: 12px;
  margin-bottom: 8px;
}

.nom {
  color: orange;
  text-transform: lowercase;
}

.back-cgu {
  position: absolute;
  width: 26px;
  height: 26px;
  margin-top: 32px;
  margin-left: 4px;
}

/* css de la page confidentialités */
.politique-div-container {
    padding-top: 110px;
    width: 60%;
    margin: auto;
    margin-bottom: 90px;
    text-align: center;
}

.main-title-conf {
  font-size: 36px;
}

.second-title-conf {
  font-size: 20px;
  margin-bottom: 70px;
}

.private-div {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.div-art-conf {
  margin-bottom: 75px;
  width: 250px;
}

.icon-conf {
  width: 35px;
  margin-right: 10px;
}

.img-title-conf {
  display: flex;
  flex-direction: row;
}

.private-div > div {
  width: 45%;
  justify-content: space-between;
}

.private-div > div > div > p {
  line-height: 1.4;
  text-align: left;
  color: grey;
  font-size: 14Px;
  margin-top: 12px;
}

.div-title-conf {
  width: 82%;
}

.plus-conf {
  color: black;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.p-news-conf, .p-news-conf2 {
  line-height: 1.5;
  text-align: left;
  margin-top: 10px;
  background-color: #F5F5F5;
  padding: 14px;
  border-radius: 4px;
  position: absolute;
  width: 60%;
  font-size: 14Px;
  color: #000;
  z-index: 111;
}

.maj-conf {
  font-size: 11px;
  font-style: italic;
}

