.nav-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding-bottom: 79px;
}

.img-arrow-dropdown {
  width: 20px;
  height: 20px;
}

.grasTitle {
  color: #bd8508;
  font-weight: 500;
}

p {
  width: 100%;
  margin: auto;
  line-height: 1.4;
  text-align: center;
}

li {
  list-style: none;
}

.margD {
  margin-top: 30px;
  margin-bottom: 30px;
}

.fixe {
  height: 69px;
  background-color: white;
  transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 20px 0px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.burger-mobile {
  width: 33px;
  height: 33px;
  margin-top: 20px;
  margin-right: 18px;
}

.img-nom-site {
  display: none;
}

.pop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 6%;
  color: white;
}

.eclair {
  background-color: rgb(196, 140, 4);
  width: 90%;
  position: relative;
  overflow: scroll;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.aPop {
  text-decoration: none;
}

.div-eclair {
  padding: 10px;
  text-align: center;
}

.pop-title {
  font-size: 24px;
  margin-top: 15px;
}

p.div-pop {
  margin-top: 16px;
  margin-bottom: 16px;
}

.but-pop {
  width: 58%;
  background-color: #6d9101;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  margin-top: 17px;
  position: relative;
  margin-bottom: 15px;
}

.flyImg {
  width: 14%;
  height: 60%;
  position: absolute;
  top: 19px;
  right: 72px;
}

.but-send-pop {
  text-align: center;
}

.but-pop2 {
  width: 230px;
  color: rgb(128, 154, 118);
  border: 1px solid rgb(128, 154, 118);
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  margin: auto;
  margin-top: 10px;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.but-pop2:hover {
  color: white;
  background-color: rgb(128, 154, 118);
}

.flyImg2 {
  width: 11%;
  height: 53%;
  position: absolute;
  top: 15px;
  left: 57px;
  z-index: 20;
}

.aide-header {
  font-family: 'Playlist.otf';
  font-size: 32px;
  color: white;
  margin-bottom: 30px;
  margin-top: 18px;
}

.link-team {
  text-decoration: none;
  color: white;
}

.div-pop-position {
  position: relative;
  text-align: center;
}

.input-pop {
  padding: 12px;
  border: none;
  border-radius: 5px;
}

.essai {
  position: absolute;
  margin-top: 32px;
}

.icon-pop {
  position: relative;
  top: 34px;
  left: 291px;
  font-size: 21px;
  background-color: rgba(196, 140, 4, 0.1);
  color: white;
  z-index: 20;
  cursor: pointer;
}

.overflow-hidden {
  display: none;
}

.display {
  left: 0;
}

.pad {
  line-height: 1.4;
  color: white;
  font-weight: bold;
}

.barre-navigation {
  display: flex;
  width: 90%;
  margin: auto;
  z-index: 2;
  position: relative;
}

.backColor {
  background-color: #898888;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#div-tigroo {
  background: linear-gradient(
    0deg,
    rgba(227, 187, 92, 1) 0%,
    rgba(189, 133, 8, 1) 100%
  );
  padding-top: 74px;
  text-align: center;
}

.img-mobile {
  width: 340px;
  height: 260px;
}

.head-title {
  color: #fff;
  font-size: 34px;
  animation: slidein 1s ease;
  font-weight: 700;
  padding: 0px 30px 0px 30px;
}

.div-qrcode-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.first {
  background-image: url('../images/img-header.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 86px;
  width: 90%;
  margin: auto;
  margin-top: 65px;
  position: relative;
}

.title-header-first {
  position: absolute;
  left: 68px;
  bottom: 246px;
}

.title-gratuit {
  color: white;
  font-size: 32px;
  font-family: 'Playlist.otf';
}

.img-carte-bleue {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  margin-left: 12px;
}

.title-carte {
  margin-top: 0px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.first-img-header {
  height: 220px;
  position: relative;
}

.first-title {
  font-size: 30px;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 29px;
  margin-right: 10px;
  background-color: #7147ff;
  color: white;
  width: 35%;
  margin: auto;
  margin-bottom: 5px;
}

.img-qrcode {
  width: 80px;
  height: 80px;
  margin-top: 28px;
  margin-left: 20px;
}

.tigroo-title {
  font-size: 44px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px;
  margin-bottom: 28px;
  color: white;
}

.div-plus {
  margin-bottom: 0px;
  padding: 18px;
  color: white;
  display: none;
}

.p-news {
  width: 80%;
  z-index: 11;
  line-height: 1.5;
  font-weight: 400;
  background-color: transparent;
  padding-bottom: 20px;
  color: white;
}

.but-1 {
  width: 236px;
  background-color: #bd8508;
  color: white;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  font-weight: 500;
}

.but-1:hover {
  background-color: #e3bb5c;
}

.but-2 {
  background-color: #f5f5f5;
  width: 236px;
  color: #bd8508;
  border: 1px solid #bd8508;
  border-radius: 20px;
  margin-bottom: 40px;
  padding: 10px;
  font-weight: 500;
}

.but2-marg {
  margin-top: 30px;
}

.but-2:hover {
  background-color: #e3bb5c;
  color: white;
}

.but-marg {
  margin-bottom: 30px;
  margin-top: 12px;
}

.chevron {
  display: none;
}

.header-mobile-provisoire {
  display: none;
  flex-direction: row;
}

.hide {
  transform: translateX(-105%);
}

.view {
  transform: translateX(0%);
}

.opa {
  opacity: 0 !important;
}
