/* bootstrap css*/
.navbar > .container-fluid {
  background-color: white;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #bd8508;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(189 133 8 / 25%);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bd8508'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.navbar {
  height: 72px;
  z-index: 1050;
}

.dropdown-menu {
  border: 1px solid transparent;
  padding: 0px;
  margin: 0px;
}

.navbar > .nav-link {
  padding: 0rem;
}

.dropdown-item {
  color: black;
  color: rgba(0, 0, 0, 0.55);
  font-weight: 300px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: transparent;
}

.div-label-footer {
  width: 95%;
}

.dropdown-menu.show {
  display: grid;
  border: none;
}

.d-flex {
  flex-direction: column;
  margin-bottom: 30px;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #e3bc5e;
  border-color: #e3bc5e;
  box-shadow: 0 0 0 0.25rem rgb(189, 133, 8 / 50%);
}

.btn-primary {
  color: #fff;
  background-color: #bd8508;
  border-color: #bd8508;
}

.btn-outline-primary {
  color: #bd8508;
  border-color: #bd8508;
  background-color: white;
}

.btn-primary:hover {
  color: white;
  border-color: #e3bc5e;
  background-color: #e3bc5e;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #e3bc5e;
  border-color: #e3bc5e;
}

.btn-marg {
  margin: 13px;
}

.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: white;
}

.dropdown .dropdown-menu .dropdown-item:focus {
  outline: none;
}

.navbar-light .navbar-nav .nav-link {
  outline: none !important;
}

.accordion {
  width: 90%;
  margin: auto;
}

.navbar-color {
  background-color: white;
  color: lightslategray;
}

.accordion-button:not(.collapsed) {
  color: #bd8508 !important;
  background-color: #e3bc5e;
  box-shadow: inset 0 -1px 0 rgb(189, 133, 8 / 13%) !important;
}

.accordion-button.collapsed {
  background: #bd8508;
  color: white;
  padding: 24px;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

a {
  text-decoration: none;
}

/**/

/*img {
  max-width: 100%;
  height: auto;
}*/

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0px;
  }
}

.buttons-head-mobile {
  display: flex;
  flex-direction: column;
}

.div-picture {
  z-index: 111111;
}

.marg-button-head-mobile {
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-ul-header {
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
}

.ul-li-header {
  color: white;
  text-align: left;
}

.ul-li-header > li:before {
  content: '✓';
  margin-right: 6px;
}

.div-container-fonct {
  width: 98%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 0px;
}

.div-cartes-fonct {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  margin-bottom: 90px;
}

.div-slide-cartes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-slide-cartes-reverse {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-cartes-fonct-reverse {
  width: 46%;
  display: flex;
  flex-direction: row-reverse;
  margin: auto;
  align-items: center;
  margin-bottom: 90px;
}

.appear {
  opacity: 0;
  position: relative;
  top: 250px;
}

.fonct-mobile {
  margin-bottom: 40px;
}

.text-bold {
  color: white;
  font-weight: bold;
}

.p-fonct {
  font-size: 20px;
  margin-top: 22px;
  position: relative;
  background: #bd8508;
  color: white;
  border-radius: 0.4em;
}

.p-fonct-reverse:after {
  display: none;
}

.p-fonct-reverse {
  font-size: 20px;
  position: relative;
  background: #bd8508;
  color: white;
  border-radius: 0.4em;
  padding: 10px;
  width: 100%;
  margin-top: 20px;
  margin-left: 0x;
}

.p-fonct:after {
  display: none;
}

.icone-oeil {
  width: 30px;
  height: 30px;
  margin-left: 6px;
}

.div-title-fonct {
  margin-bottom: 45px;
}

.img-menu-fonct {
  width: 280px;
  height: 530px;
}

.div-digit-container {
  width: 80%;
  margin: auto;
}

.div-digit-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.menus {
  margin-bottom: 65px;
}

.div-plus-button {
  margin-top: 20px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 8px;
}

button {
  border: none;
  background-color: none;
  box-shadow: none;
  padding: 0px;
  background-color: white;
}

.btn-tarif {
  margin-top: 30px;
}

.logo-img1 {
  width: 45px;
  height: 45px;
  max-height: inherit;
}

.logo-img2 {
  width: 108px;
  height: 34px;
  max-height: inherit;
}

.border-button {
  border-radius: 20px;
}

.header-buttons {
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.g-recaptcha {
  position: relative;
}

.mots-cle {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.first {
  margin-top: 30px;
}

.img-mobile {
  width: 329px;
  height: 415px;
}

.motcle-gras {
  color: #c48c04;
}

.motcle-gras2 {
  font-size: 14px;
  font-weight: bold;
}

.articles-mot-cle {
  text-align: center;
  width: 85%;
  margin: auto;
  margin-bottom: 55px;
}

.title-art-features {
  text-align: center;
  width: 75%;
  margin: auto;
  padding-top: 104px;
  margin-bottom: 45px;
}

.articles-features {
  width: 85%;
  margin: auto;
  margin-bottom: 45px;
}

.title-feature {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
}

.pad-ipad {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.menu-dig {
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 10px;
  font-size: 16px;
}

.desktop {
  display: none;
}

.plus {
  display: none;
}

.plus2 {
  font-weight: 600;
  color: #bd8508;
  margin-top: 16px;
  cursor: pointer;
  margin-bottom: 16px;
}

/*partie: comment ça marche ? */
.marge {
  margin-bottom: 35px;
}

.working-art {
  padding-top: 10px;
  padding-bottom: 10px;
}

.work-part {
  text-align: center;
}

.work-art {
  margin-top: 10px;
  margin-bottom: 40px;
}

.ico-title {
  font-size: 28px;
  margin-bottom: 12px;
  color: #bd8508;
  font-weight: 500;
  cursor: default;
  margin-top: 50px;
}

.ico-title:hover {
  color: #e3bc5e;
}

.card {
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  box-shadow: 6px 14px 22px -14px rgb(125 125 125);
  border-radius: 2px;
  border: none;
  margin-bottom: 55px;
}

.container-confiance {
  width: 78%;
  margin: auto;
}

#menu-simple-restaurateur {
  width: 80%;
  margin: auto;
}
.box {
  width: 300px;
  height: 400px;
  perspective: 1000px;
  margin-bottom: 47px;
}
.box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.2s;
  transform-style: preserve-3d;
  box-shadow: 0 40px 125px rgb(0 0 0 / 7%);
}

.flip {
  transform: rotateY(180deg);
}

.img-card-how {
  width: 300px;
  height: 190px;
}

.swipe-back-p {
  padding: 14px;
}

.display-card {
  display: flex;
  flex-direction: column;
}

.card-front-back {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/*partie: Exemple de menu digitalisé*/
.img-exemple-cahier {
  width: 404px;
  height: 572px;
}

.img-exemple-cahier-rappel {
  width: 250px;
  height: 250px;
  border-radius: 60%;
  margin-top: 28px;
  margin-left: 24px;
}

.table tr {
  display: block;
  padding: 0;
  margin: 0;
}

.container-tarifs {
  width: 100%;
  margin: auto;
}

table td {
  border: 1px solid lightgrey;
  color: #292929;
  padding: 12px 6px 12px 6px;
  margin: 0;
}

.img-check {
  width: 25px;
  height: 25px;
  text-align: center;
}

table tr .td-img-check {
  text-align: center;
}

.p-tarifs {
  width: 58%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

thead tr th {
  padding: 14px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.table-mobile {
  width: 92%;
  margin: auto;
}

.title-border-tarifs {
  border: 1px solid lightgrey;
  font-weight: bold;
}

.button-like {
  color: white;
  font-size: 9px;
}

.button-tarifs {
  width: 81px;
}

.btnMobile {
  margin-top: 12px;
  margin-bottom: 12px;
}

.no-border {
  border: 1px solid transparent;
  text-align: center;
  padding-top: 40px;
}

.title-cookie-tarifs {
  color: #bd8508;
}

.features-tarifs {
  background-color: #f0f0f2;
}

.head-tarifs {
  padding: 20px;
  text-align: center;
}

.img-ipad {
  width: 100%;
  height: 100%;
}

.div-img-ipad {
  display: flex;
  width: 45%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.div-img-cahier {
  width: 300px;
  height: 300px;
  width: 80%;
  margin: auto;
  margin-bottom: 62px;
}

.cards-list {
  width: 75%;
  margin: auto;
  margin-top: 19px;
}

/*partie: Fonctionnalités*/
.container-features {
  width: 90%;
  margin: auto;
}

table td {
  font-size: 14px;
}

.background-td {
  color: #5a5c63;
  font-weight: bold;
}

.gras-td {
  color: #5a5c63;
  font-weight: bold;
}

table tr .center-td {
  text-align: center;
}

/*partie: Menu-exmple*/
#cahier-de-rappel {
  width: 94%;
  margin: auto;
}

.p-cahier-exemple {
  width: 70%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 5px;
}

.img-cahier-qrcode-menu1, .img-cahier-qrcode-menu2 {
  width: 100%;
}

#menu-ex {
  margin-top: 128px;
  margin-bottom: 50px;
}

.mobile-menu-title {
  width: 75%;
  margin: auto;
  margin-bottom: 12px;
  text-align: center;
}

.menuex-p {
  padding: 20px;
}

.button-menu-ex {
  text-align: center;
  margin: 20px;
}

.btn-menus-ex {
margin-bottom: 11px;
}

#button-retour.button-haut {
  bottom: 85px;
  right: 25px;
  opacity: 1;
  display: none;
}

.div-button-menus-ex1, .div-button-menus-ex2 {
  display: flex;
  flex-direction: column;;
}

.div-tarifs,
.div-confiance,
.cards-div {
  margin-bottom: 60px;
}

.div-info-cahier {
  width: 80%;
  margin: auto;
  margin-top: 145px;
  margin-bottom: 60px;
}

.div-button {
  margin: auto;
  margin-top: 42px;
  text-align: center;
}

#tableau-comparatif {
  margin-top: 130px;
}

.button-fonct-marg {
  margin-bottom: 40px;
}

.div-para-cahier {
  padding: 14px;
  box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
}

.img-cahier-qrcode {
  width: 255px;
  height: 202px;
  margin-bottom: 32px;
}

.div-para-cahier {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 0px;
}

.container-infos-gvt {
  margin-bottom: 40px;
}

.deco-p {
  text-decoration: underline;
  font-style: italic;
}

.cahier-ancre {
  color: white;
}

.p-cahier {
  margin-bottom: 35px;
}

.cahier-ancre:hover {
  color: whitesmoke;
}

.para-cahier {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.img-cahier-index {
  display: none;
}

.div-confiance {
  margin-bottom: 60px;
}

.div-menu-ex {
  width: 85%;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 30px;
}

/*exemple de menu*/
.container-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-card-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.container-description {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.img-description {
  width: 199px;
  height: 199px;
  margin: auto;
  border-radius: 50%;
}

.div-line {
  display: none;
}

.description-mobile-marg {
  margin-bottom: 100px;
}

.description-mobile-margBis {
  margin-bottom: 0px;
}

.description-p-title {
  font-weight: bold;
  color: #bd8508;
  font-size: 18px;
  margin-bottom: 20px;
}

.title-description {
  color: #ffffff;
  width: 48px;
  border: solid 1px #bd8508;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 44px;
  justify-content: center;
  background-color: #bd8508;
}

.div-title-description {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.div-img-description {
  width: 200px;
  height: 200px;
  border: 1px solid beige;
  border-radius: 50%;
}

.card-description-p {
  padding-top: 22px;
  width: 100%;
  margin: auto;
}

/*partie: questions accordéon*/
.acc-marg {
  margin-top: 20px;
  margin-bottom: 40px;
}

/* partie accordéon*/
.container {
  width: 100%;
  margin: auto;
  margin-top: 98px;
  margin-bottom: 80px;
}

.title-container-accordion {
  width: 75%;
  margin: auto;
  margin-bottom: 50px;
}

.img-accordeon {
  width: 36px;
  height: 36px;
  margin: 35px 14px 0px 4px;
}

.div-number1 {
  padding: 14px;
  border-radius: 57%;
  width: 40%;
  margin: auto;
  margin-bottom: 22px;
}

.div-number2 {
  padding: 14px;
  border-radius: 57%;
  width: 40%;
  margin: auto;
  margin-bottom: 22px;
}

.p-pad-ipad {
  padding-top: 6px;
}

.arrow,
.arrowBis {
  display: none;
}

.cahier-rappel-img {
  margin-bottom: 40px;
}

/*Partie : formulaire*/
.red,
.green {
  color: grey;
  text-align: initial;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}

.red {
  background-color: #f57920;
}

.green {
  background-color: #33ab2c;
}

.form-mobile {
  width: 90%;
  margin: auto;
}

#input-motivation {
  height: 1.6rem;
  background-color: hsla(0, 0%, 100%, 0.9);
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 5px;
}

input[type='email']:not(.browser-default),
input[type='password']:not(.browser-default) {
  height: 2rem;
  width: 96%;
  font-size: 12px;
  margin: 0 0 0px 0;
}

.verify-message {
  width: 75%;
  margin: auto;
  line-height: 0.8;
  margin-top: 10px;
}

#errorlabelConfirmPassword {
  text-align: initial;
  font-size: 12px;
  font-weight: 200;
  color: red;
}

.div-email {
  margin-bottom: 12px;
}

#passwordContainer {
  margin: auto;
  margin-top: 25px;
}

.passLabel {
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: auto;
  font-size: 14px;
}

.passwordLabelInterrogation {
  display: flex;
}

.div-interrogation {
  margin: auto;
  margin-top: 36px;
  margin-bottom: 15px;
}

.div-interrogation2 {
  margin: auto;
  margin-top: 22px;
  margin-bottom: 15px;
}

.messageX {
  position: absolute;
  top: -9px;
  right: -4px;
  padding: 9px;
  color: black;
}

.formul-laptop {
  margin-top: 20px;
  margin-bottom: 40px;
}

#message {
  position: absolute;
  top: 357px;
  left: 640px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  padding: 15px;
  width: 26%;
  color: #353535;
  line-height: 1.4;
  background-color: white;
  padding: 15px;
}

#essai {
  display: none;
  margin-top: 0;
}

#poste {
  margin-bottom: 0;
}

#essai.montrer {
  display: block;
  margin-top: 8px;
}

.details-form {
  padding: 8px;
  margin-bottom: 50px;
  margin-top: 26px;
}

.form-dispo {
  padding-bottom: 40px;
  margin-top: 80px;
}

.form {
  background-color: white;
  padding: 14px;
  width: 85%;
  margin: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.form-flex {
  display: flex;
}

.form-input {
  box-shadow: 1px 3px 10px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: auto;
  width: 240px;
  margin-top: 10px;
  border: 1px solid white;
  padding: 6px;
}

.div-but-formul {
  width: 100%;
  margin-bottom: 12px;
}

.conditions-check {
  color: black;
}

::placeholder {
  color: #c3cfd9;
}

.check {
  width: 240px;
  margin: auto;
  margin-top: 24px;
}

.connect {
  color: rgb(128, 154, 118);
  margin-bottom: 10px;
  text-decoration: underline;
}

.connect2 {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.formul-label {
  display: flex;
  margin: auto;
  text-align: initial;
  font-weight: 700;
  font-size: 14px;
  color: lightslategrey;
}

.div-point {
  margin-left: 10px;
}

.input-point {
  margin-top: 4px;
}

#message {
  position: absolute;
  top: 429px;
  left: 124px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  padding: 15px;
  width: 54%;
  color: #353535;
  line-height: 1.4;
  background-color: white;
  padding: 5px;
}

.passwordAide {
  position: relative;
  z-index: 0;
}

.validation-form {
  font-size: 12px;
  margin-bottom: 36px;
  font-weight: 400;
}

.contact-div {
  width: 85%;
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
}

.title-contact-div {
  color: white;
  text-align: center;
  font-weight: bold;
}

.tarif-card-title {
  color: #bd8508;
  text-align: center;
  padding-bottom: 20px;
}

.row .col.s6 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.section-tarif {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 20px;
  font-style: italic;
}

.title-tarifs {
  text-align: center;
}

.div-carte-header {
  margin-bottom: 42px;
}

.div-img-menus-ex {
  width: 95%;
  margin: auto;
}

.title-cards {
  text-align: center;
  color: #292929;
  font-size: 20px;
  font-weight: 400;
  width: 71%;
  margin: auto;
  margin-top: 34px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.card-1 {
  width: 40px;
  height: 40px;
}

.card-content {
  width: 100%;
}

#menu-simple-client {
  margin-top: 80px;
}

.card.horizontal .card-stacked .card-content {
  padding-top: 0px;
  margin-bottom: 25px;
}

.mobile-marg-feature {
  margin-bottom: 40px;
}

.waves-effect.waves-light {
  background-color: white;
  color: #bd8508;
  font-weight: 164px;
}

.card.horizontal {
  display: flex;
  width: 75%;
  margin: auto;
  margin-bottom: 65px;
  padding-bottom: 24px;
}

.features-p {
  padding: 6px;
}

.img-team-propos {
  display: flex;
  justify-content: center;
}

.typo {
  font-size: 24px;
  color: #bd8508;
  letter-spacing: 0.6px;
}

.dropdown-content li > span > label {
  color: #26a69a;
}

.container-tarif {
  margin-bottom: 50px;
}

.card .card-content .card-title {
  padding: 6px;
  padding-bottom: 24px;
  font-weight: bold;
}

.card .card-image img {
  width: 150px;
  height: 150px;
}

.icone-confiance {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.transform-text-confiance {
  color: #bd8508;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.card-title {
  text-align: center;
}

.name-confiance-caramel {
  width: 30%;
  color: #bd8508;
  font-weight: bold;
}

.accroche-confiance {
  color: #bd8508;
  font-style: italic;
  margin-bottom: 28px;
}

.para-confiance {
  color: #777a81;
}

.img-news-content {
  width: 23px;
  height: 23px;
  margin-right: 7px;
  margin-bottom: 10px;
}

.form-error {
  color: #d8000c;
}

#mapid {
  height: 400px;
  width: 330px;
  margin: auto;
  border-radius: 5px;
}

.container-map {
  width: 90%;
  margin: auto;
  margin-bottom: 40px;
}

.title-map {
  text-align: center;
  margin-bottom: 70px;
}

.div-map {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.video-container {
  width: 70%;
  margin: auto;
  padding-top: 94px;
  margin-bottom: 30px;
}

.demo-title {
  margin: auto;
  margin-bottom: 0px;
}

.div-video-responsive {
  text-align: center;
}

.video-demo-resto {
  width: 360px;
  margin-bottom: 36px;
}

.exemple-cahier-div2 {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-bottom: 54px;
  margin-top: 50px;
}

.img-art-qrcode {
  width: 23px;
  height: 23px;
  margin-left: 5px;
  margin-top: 3px;
}

.para-art-qrcode {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
}

.article-resto-qrcode {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.play-btn {
  width: 60px;
  height: 60px;
  background: radial-gradient(#bd8508 60%, rgba(255, 255, 255, 1) 62%);
  border-radius: 50%;
  position: absolute;
  top: 218px;
  left: 155px;
  display: block;
  box-shadow: 0px 0px 25px 3px #bd8508;
}

/* triangle */
.play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid white;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.btn-transparent {
  content: '';
  background: transparent;
  box-shadow: none;
  border: transparent;
}

.video {
  width: 261px;
  position: relative;
  margin-top: 24px;
}

.fonct-header {
  color: white;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  text-decoration: underline;
}

.fonct-header:hover {
  color: #bd8508;
}

.acc-title {
  text-align: center;
  color: #bd8508;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 15px;
}

.card-img-top {
  height: 100px;
  width: 66%;
}

.div-card-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: text;
}

.btn-ajust-width {
  width: 208px;
}
